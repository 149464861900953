<template>
  <div class="worksheet-detail-page-box">
    <div>
      <!-- 公司名称 -->
      <div class="row-flex">
        <h3 class="company-name">
          {{ detailObj.entrustClientName
          }}<span>委托客户</span>
        </h3>
        <h3 class="company-name">
          {{
            detailObj.processClientName
          }}<span>加工方</span>
        </h3>
      </div>

      <div class="detail-box">
        <div class="item">
          <i>加工单号</i>
          <span>{{ detailObj.factoryOrderNo }}</span>
        </div>
        <div class="item">
          <i>创建时间</i>
          <span>{{ detailObj.createTime }}</span>
        </div>
        <div class="item">
          <i>期望交期</i>
          <span>{{ detailObj.expectDeliveryTime }}</span>
        </div>
        <div class="item">
          <i>委托方联系人</i>
          <span>{{
            detailObj.entrustContactPerson
          }}&nbsp;&nbsp;{{
            detailObj.entrustContactPhone
          }}</span>
        </div>
        <div class="item">
          <i>加工方联系人</i>
          <span>{{
            detailObj.processContactPerson
          }}&nbsp;&nbsp;{{
            detailObj.processContactPhone
          }}</span>
        </div>
        <div class="item">
          <i>状态</i>
          <span>{{
            getDictName(statusDictionary, detailObj.state)
          }}</span>
        </div>
      </div>
      <h3 class="page-title">
        物料信息
        <p>物料所在地： {{ detailObj.materialProvinceName + detailObj.materialCityName + detailObj.materialRegionName }}</p>
      </h3>
      <div class="table-box">
        <Table
          :item-data="itemData"
          :list-data="detailObj.factoryOrderMaterialList"
        >
          <template #quantity="{ row }">
            <span>{{ row.quantity
            }}{{ getDictName(purchaseUnit, row.unit) }}</span>
          </template>
        </Table>
      </div>
      <h3 class="page-title">
        加工信息
      </h3>
      <div class="process-information-box">
        <span>具体加工要求</span>
        <p>
          {{ detailObj.processRequire }}
        </p>
      </div>
      <div class="process-information-box">
        <span>收货地址</span>
        <p>{{ detailObj.deliveryAddress }}</p>
      </div>
      <div class="process-information-box">
        <span>附件</span>
        <div class="image-box">
          <img
            v-for="(item, index) in detailObj
              ?.tradeFactoryOrderAttachmentList"
            :key="index"
            :src="$fileUrl + item.attachUrl"
            alt="图片"
            @click="viewImage($fileUrl + item.attachUrl)"
          >
        </div>
      </div>
      <!-- 这个是报价回复 -->
      <div class="quotation-reply-box">
        <h3 class="page-title">
          报价回复
        </h3>
        <div v-if="detailObj.replayDeliveryTime" class="process-information-box">
          <span>回复交期</span>
          <p>{{ detailObj.replayDeliveryTime }}</p>
        </div>
        <div class="process-information-box">
          <span>加工厂地址</span>
          <p>
            {{ detailObj.processFactoryProvinceName
            }}{{ detailObj.processFactoryCityName
            }}{{ detailObj.processFactoryRegionName
            }}{{ detailObj.processFactoryAddressDesc }}
          </p>
        </div>
        <!-- 填写数据的地方 -->
        <div v-if="detailObj.factoryOrderProcessContentList?.length > 0" class="quote-table-box">
          <Table
            :list-data="detailObj.factoryOrderProcessContentList"
            :item-data="quotationReply.itemData"
            :show-overflow-tooltip="false"
            show-index
          >
            <!-- <template #unit="{ row }">
              <span>{{ getDictName(purchaseUnit, row.unit) }}</span>
            </template> -->
          </Table>
          <div class="total-box">
            合计：数量&nbsp;&nbsp;{{
              quotationReply.numTotal || 0
            }}，金额&nbsp;&nbsp;{{ quotationReply.moneyTotal || 0 }}
          </div>
        </div>
      </div>
      <!-- 是详情的时候才加上步骤条 -->
      <div v-if="isDetail">
        <h3 class="page-title">
          加工单跟踪
        </h3>
        <!-- 加工单步骤条 -->
        <Step :detail-obj="detailObj" />
      </div>
    </div>
    <!-- 查看图片的弹窗 -->
    <el-dialog :visible.sync="dialogVisible" title="预览" width="40%">
      <img style="width: 100%;" fit="contain" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>

<script>
import Table from '@/components/Table.vue'
import Step from '../components/Step.vue'
import { floatMul } from '@/utils/util'
import {
  queryFactoryOrderDesc
} from '@/api/processingServices'
import {
  getDictName
} from '@/utils/util.js'
export default {
  components: { Table, Step },
  data() {
    return {
      getDictName,
      cascader: null,
      isDetail: true,
      itemData: [
        { label: '品名 ', prop: 'goodsName' },
        { label: '材质 ', prop: 'goodsMaterialName' },
        { label: '规格 ', prop: 'goodsSpecName' },
        { label: '产地 ', prop: 'manufactor' },
        { label: '数量 ', prop: 'quantity' } // 单位是unit
      ],
      detailObj: {},
      dialogImageUrl: '',
      dialogVisible: false,
      quotationReply: {
        itemData: [
          { label: '加工内容 ', prop: 'processContent' },
          { label: '数量', prop: 'quantity' },
          { label: '单位 ', prop: 'unit' },
          {
            label: '加工单价(含税) ',
            prop: 'includeFaxUnitPrice'
          },
          { label: '小计(元)', prop: 'totalPrice' },
          { label: '备注 ', prop: 'remark' }
        ],
        moneyTotal: 0,
        numTotal: 0
      },
      purchaseUnit: this.$store.getters.getDictionaryItem('purchaseUnit'),
      statusDictionary: this.$store.getters.getDictionaryItem('FACTORY_ORDER_STATUS')
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      if (this.$route.query.id) {
        queryFactoryOrderDesc(this.$route.query.id, (res) => {
          this.detailObj = this.quotationReply.ruleForm = { ...res.data }
          this.detailObj.factoryOrderMaterialList.forEach((item) => {
            item.goodsName = item.goodsTopCategoryName + item.goodsOneCategoryName + item.goodsTwoCategoryName
          })
          this.detailObj.factoryOrderProcessContentList.forEach((item) => {
            item.totalPrice = floatMul(item.quantity, item.includeFaxUnitPrice).toFixed(2)
          })
          const tatol = this.detailObj.factoryOrderProcessContentList.reduce((prev, current) => prev + (Number(current.totalPrice) || 0), 0)
          this.quotationReply.moneyTotal = tatol.toFixed(2)
          const num = this.detailObj.factoryOrderProcessContentList.reduce((prev, current) => prev + (current.quantity || 0), 0)
          this.quotationReply.numTotal = num
        })
      }
    },
    // 查看图片
    viewImage(url) {
      this.dialogVisible = true
      this.dialogImageUrl = url
    }
  }
}

</script>

<style lang="scss" scoped>
.worksheet-detail-page-box {
  background-color: #fff;
  padding: 0;
  .row-flex {
    display: flex;
    > h3 {
      margin-right: 300px;
    }
  }
  > div {
    padding: 32px 40px;
  }
  .quote-table-box {
    display: flex;
    // align-items: center;
    flex-direction: column;
    border: 1px solid #ededed;
    :deep(.el-icon) {
      color: #333;
    }
    :deep(.el-input__inner) {
      text-align: left;
    }
    .total-box {
      height: 56px;
      line-height: 56px;
      padding-left: 16px;
      color: #333333;
      font-size: 14px;
      font-weight: 400;
    }
  }
  :deep(.el-form) {
    .el-form-item {
      margin-bottom: 24px;
    }
    .flex-box {
      display: flex;
      width: 100%;
      .breadth-box {
        flex: 0 0 360px;
      }
    }

    .breadth-box {
      width: 360px !important;
      margin-right: 8px;
    }
  }
  .button-assembly {
    margin-top: 76px;
    border-top: 1px solid #f3f3f3;
    button {
      height: 32px;
      line-height: 32px;
      box-sizing: border-box;
      padding: 0 16px;
      color: #333333;
      font-size: 14px;
      font-weight: 400;
      margin-right: 8px;
      border: 1px solid #e7e7e7;
      background-color: #fff;
      outline: 0;
      border-radius: 3px;
    }
    button:first-of-type {
      background-color: #0172eb;
      border: 1px solid #0172eb;
      color: #fff;
    }
  }
  .process-information-box {
    display: flex;
    align-items: flex-start;
    color: #000000;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 16px;
    .image-box {
      display: flex;
      img {
        height: 80px;
        width: 80px;
        margin-right: 8px;
      }
    }
    span {
      flex: 0 0 112px;
      padding-right: 16px;
      color: #666666;
    }
    p {
      flex: 1;
      line-height: 1.6;
    }
  }
  .page-title {
    height: 20px;
    line-height: 20px;
    padding-left: 8px;
    border-left: 4px solid #0172eb;
    color: #000000;
    font-size: 20px;
    font-weight: 500;
    margin: 56px 0 32px;
    display: flex;
    p {
      margin-left: 20px;
      font-size: 14px;
      color: #333;
    }
  }
  .table-box {
    border: 1px solid #ededed;
    border-bottom: none;
  }
  .detail-box {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .item {
      display: flex;
      align-items: center;
      flex: 0 0 33.33%;
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 16px;
      span {
        color: #000000;
      }
      i {
        font-style: normal;
        flex: 0 0 128px;
        color: #666666;
      }
    }
  }
  .company-name {
    color: #000000;
    font-size: 20px;
    font-weight: 500;
    padding-bottom: 44px;
    span {
      display: inline-block;
      margin-left: 16px;
      width: 72px;
      height: 28px;
      line-height: 28px;
      text-align: center;
      color: #0172eb;
      background-color: rgba(1, 114, 235, 0.05);
      font-size: 14px;
    }
  }
}
</style>
